.link {
  text-decoration: none;
  color: white;
  &:hover {
    text-decoration: none;
  }

  &:active {
    text-decoration: none;
  }
}

.active_link {
    color: grey;
}