.logo {
    margin: 0.5rem 0;
    width: 6rem;
}

.homeLink {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: white;
}