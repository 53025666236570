:root {
    --bg: #fdfdfd;
    --highlight1: #F26A51;
    --highlight2: #FFC956;
    --color: #1a1e24;
    --font-number: Montserrat, Roboto, Helvetica, Arial, sans-serif;
    --font-head: "Space Mono", Consolas, Menlo, Monaco, "Courier New", monospace;
    --font: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.list {
    list-style: none;
    // width: 600px;
    max-width: 90%;
}
.item {
    display: block;
    clear: both;
    counter-increment: list;
    padding-bottom: 4rem;
    font-size: 1.1rem;
    line-height: 1.375;
    position: relative;

    &::before {
        font: bold 2.25rem/1 var(--font-number);
    content: counter(list);
    width: 5rem;
    height: 5rem;
    float: left;
    margin: 0 1.5rem 0.75rem 0;
    color: var(--bg);
    background: var(--highlight1) linear-gradient(to bottom right, var(--highlight1) 25%, var(--highlight2));
    text-shadow: 0 0 2px var(--highlight1);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    shape-outside: ellipse();
    z-index: 1;
    }

    &::after {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background: var(--highlight1);
        z-index: -1;
        border-top-left-radius: 3px;
    }
}


.headline {
    padding: 0rem 0 0 0;
    margin: 0 0 1rem 0;
    font: normal 2rem var(--font-head);
}

// .item:before {
//     font: bold 2.25rem/1 var(--font-number);
//     content: counter(list);
//     width: 5rem;
//     height: 5rem;
//     float: left;
//     margin: 0 1.5rem 0.75rem 0;
//     color: var(--bg);
//     background: var(--highlight1) linear-gradient(to bottom right, var(--highlight1) 25%, var(--highlight2));
//     text-shadow: 0 0 2px var(--highlight1);
//     border-radius: 50%;
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     shape-outside: ellipse();
//     z-index: 1;
// }
// .item:after {
//     width: 2.5rem;
//     height: 2.5rem;
//     position: absolute;
//     top: 0;
//     left: 0;
//     content: "";
//     background: var(--highlight1);
//     z-index: -1;
//     border-top-left-radius: 3px;
// }