.linkGroup {
    padding: 0 0;
    display: flex;
    list-style-type: none;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.link {
    // text-decoration: none;
    color: inherit;
    padding: 0 0.75rem;
}