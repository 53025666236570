.link {
  text-decoration: none;
  color: white;
}

.active_link {
  // color: rgb(117, 116, 116);
  color: black;
  // color: red;
}

.nav_list {
  display: flex;
  list-style-type: none;
  justify-content: space-between;
}

.nav_item {
  :hover {
    text-decoration: underline;
  }
  margin-left: 2rem;
}
