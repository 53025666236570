.Container {
  position: relative;
  max-height: 800px;
  overflow: hidden;
}

/* Style the video: 100% width and height to cover the entire window */
.Video {
  width: 100%;
  height: 100%;
//   min-height: 300px;
  // min-height: 400px;
}

/* Add some content at the bottom of the video/page */
.Content {
  position: absolute;
  top: 0;
  color: #f1f1f1;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
