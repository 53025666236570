.inner {
  color: white;
  padding: 20;
  position: absolute;
  top: 55%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}

$height: 4%;
$top-width: 3%;
$bottom-width: 8%;
// Like the iphone notch
@media screen and (min-width: 768px) {
  .notch {
    -webkit-clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      50% + $bottom-width 100%,
      50% + $top-width 100% - $height,
      50% - $top-width 100% - $height,
      50% - $bottom-width 100%,
      0 100%
    );
    clip-path: polygon(
      0 0,
      100% 0,
      100% 100%,
      50% + $bottom-width 100%,
      50% + $top-width 100% - $height,
      50% - $top-width 100% - $height,
      50% - $bottom-width 100%,
      0 100%
    );
  }
}
